<template>
    <div class="survey-step-wrapper">
        <div
            class="survey-step-wrapper__title d-flex px-6 px-md-8 py-8"
            :class="{ 'position-fixed': fixedTitle }"
        >
            <span
                v-if="isCompletedWithoutScore"
                class="icon material-symbols-outlined survey-step-wrapper__check-icon"
                data-cy="survey-step-info-outro-header-icon"
            >
                {{ materialIconCheck }}
            </span>
            <template v-else>
                {{ getTitle }}
            </template>
        </div>
        <slot name="header" />

        <div
            class="survey-step-wrapper__content"
            :class="{ 'survey-step-wrapper__content--padded': fixedTitle }"
        >
            <!-- todo MSK-11786: NOTIFICATION that I need to provide header from SurveyQuestionStep -->
            <slot name="image" />

            <div class="px-6 py-6 px-lg-8">
                <MrMarkdown
                    data-cy="survey-step-wrapper-text"
                    class="text-left"
                    :class="{ 'text-center': taskHandler && taskHandler.isOutroStep }"
                    :options="{ html: true }"
                    :source="getText"
                />

                <img
                    v-if="getImageBlobUrlFull"
                    class="rounded-lg mt-4 mb-n2 w-100"
                    :src="getImageBlobUrlFull"
                />

                <div
                    v-if="slots.content"
                    class="pt-4 pt-lg-6"
                >
                    <slot name="content" />
                </div>

                <div
                    v-if="slots.bottomText"
                    class="pt-6"
                >
                    <slot name="bottomText" />
                </div>

                <div
                    v-if="getFooterText"
                    class="survey-step-wrapper__footer mt-8"
                    data-cy="survey-step-wrapper-footer-text"
                >
                    {{ getFooterText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ContentService from '@serv/ContentService'
import MrMarkdown from '@comp/MrMarkdown.vue'
import TaskUi from '@serv/TaskUi'
import { useStore } from 'vuex'
import { useSurveyStepImage } from '@composables/useSurveyStepImage'
import { computed, useSlots } from 'vue'

defineProps({
    fixedTitle: {
        type: Boolean,
        default: false
    }
})

const slots = useSlots()
const store = useStore()

// title and text. Labels used to describe the step data to the patient
const user = computed(() => store.getters.user)
const clinicianCompleteForPatient = computed(() => store.getters.clinicianCompleteForPatient)
const patient = computed(() => clinicianCompleteForPatient.value || user.value)

const getTitle = computed(() => {
    return ContentService.sanitiseText(TaskUi.get().getStepTitle() || '', {
        patient: patient.value,
        stripMarkdown: false
    })
})

const getText = computed(() => {
    return ContentService.sanitiseText(TaskUi.get().getStepText() || '', {
        patient: patient.value,
        stripMarkdown: false
    })
})

// Optional labels. Not required to steps and provided from config
const taskHandler = computed(() => store.getters.taskHandler)

const getFooterText = computed(() => {
    return taskHandler.value.currentStep?.keyValues.footer
})

// outro step data
const outroStepViewModel = computed(() => taskHandler.value.getOutroHeaderViewModel())
const isCompletedWithoutScore = computed(() => taskHandler.value.isOutroStep && outroStepViewModel.value.mode === 'displayNoScoresOk')
const materialIconCheck = 'check_circle'

// image
const { getImageBlobUrlFull } = useSurveyStepImage()
</script>
