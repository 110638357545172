<template>
    <div
        v-if="!$route.meta.requiresAuth || $route.meta.webPortal"
        :class="{
                'web-portal': $route.meta.webPortal,
                'patient-dash': !$route.meta.webPortal
            }"
    >
        <router-view :key="$route.path" />
    </div>

    <div
        v-else
        :id="$route.name"
        class="client-dash ui relaxed grid container mr"
    >
        <div class="outer-column column">
            <Navigation />
            <NotifyMsg />
            <!-- Track route path changes instead of component changes -->
            <router-view :key="$route.path" />
        </div>
    </div>
</template>

<script>
import Analytics from '@serv/Analytics'
import Auth from '@serv/Auth'
import ConfigManager from '@config/ConfigManager'
import Logging from '@serv/Logging'
import LoginService from '@serv/LoginService'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Navigation from '@comp/MrNavigation.vue'
import NotifyMsg from '@comp/NotifyMsg.vue'
import Redirect from '@serv/Redirect'
import RegistrationService from '@serv/RegistrationService'
import ResourceService from '@serv/ResourceService'
import UnauthenticatedAuth from '@serv/Auth/UnauthenticatedAuth'
import Whoami from '@serv/Whoami'

export default {
    name: 'App',
    components: {
        Navigation,
        NotifyMsg
    },
    inject: ['$bus', '$config', '$mp'],
    data() {
        return {
            sessionTimer: null
        }
    },
    computed: {
        ...mapGetters(['owner', 'user'])
    },
    methods: {
        // Get the number of min of inactivity after which we will forcibly logout.
        // Specified on the owner keyValues, or 15m.
        getMaxInactivityMins() {
            if (ConfigManager.logoutInactivity === false) {
                return 99999
            }

            const ownerMins = (((this.owner || {}).keyValues || {}).dash || {}).logoutInactivityMins

            return ownerMins || 15
        },
        addActivityEventListeners() {
            window.addEventListener('click', this.resetTimer)
            window.addEventListener('keyup', this.resetTimer)
            window.addEventListener('keydown', this.resetTimer)
        },
        removeActivityEventListeners() {
            window.removeEventListener('click', this.resetTimer)
            window.removeEventListener('keyup', this.resetTimer)
            window.removeEventListener('keydown', this.resetTimer)
        },
        resetTimer() {
            if (ConfigManager.isMockingServer) {
                return
            }
            clearTimeout(this.sessionTimer)

            Auth.lastActivityMoment = moment()
            this.sessionTimer = setTimeout(() => {
                this.onInactiveUser()
            }, this.getMaxInactivityMins() * 60 * 1000)
        },
        onInactiveUser() {
            // If logged in, then log out
            if (this.user) {
                if (this.user.isPatient) {
                    // Ensure we go back to the correct patient start page
                    RegistrationService.logoutAndRedirect()
                } else {
                    LoginService.logout().then(() => {
                        Redirect.gotoName('Logout')
                    })
                }
            }
        },
        setDocumentTitle() {
            if (this.$route.name === 'Login') {
                document.title = this.$config.branding.dashboardLoginPageName || this.$config.branding.dashboardName
            } else {
                document.title = this.$config.branding.dashboardName
            }
        }
    },
    beforeMount() {
        const _this = this
        this.addActivityEventListeners()
        const handleSession = () => {
            // Handle logout on inactivity
            if (Auth.lastActivityMoment == undefined) {
                Auth.lastActivityMoment = moment()
            }
            let lastActivityInMinutes
            if (ConfigManager.isMockingServer) {
                lastActivityInMinutes = 1
            } else {
                lastActivityInMinutes = moment
                    .duration(moment().diff(Auth.lastActivityMoment))
                    .asMinutes()
            }

            // Handle inactivity
            const maxInactivityMins = this.getMaxInactivityMins()
            if (lastActivityInMinutes < maxInactivityMins) {
                // Initialisation, e.g. on page reload
                if (Auth.hasSession()) {
                    Whoami.getActiveUser()
                        .then(user => {
                            ResourceService.requestAll(user)
                        })
                        .catch(error => Logging.log(error))
                } else if (UnauthenticatedAuth.hasSession()) {
                    UnauthenticatedAuth.doAuth()
                        .catch(() => {
                            LoginService.logout()
                        })
                }
            } else {
                Logging.warn(
                    `Exceeded ${maxInactivityMins} mins of inactivity, logging out.`
                )
                _this.onInactiveUser()
            }
        }
        this.resetTimer()

        if (this.$config.preLogin) {
            Auth.doSilentAuth(this.$route.query.redirect)
                .then(() => handleSession())
                .catch()
        } else {
            handleSession()
        }
    },
    created() {
        if (this.$bus) {
            this.$bus.$off()
        }

        // workaround to add class for body. Used for changing background for web-portal pages.
        if (this.$route.meta?.webPortal) {
            document.body.classList.add('body-web-portal')
        }

        this.setDocumentTitle()
    },
    mounted() {
        Analytics.init(this.$mp)
    },
    beforeUnmount() {
        this.removeActivityEventListeners()
        clearTimeout(this.sessionTimer)
        this.$bus.$all.clear()
    },
    watch: {
        $route(to, from) {
            this.setDocumentTitle()

            Analytics.sendEvent('Page Viewed', {
                page: to.name,
                pageUrl: to.fullPath,
                from: from.name,
                fromUrl: from.fullPath
            })
        }
    }
}
</script>

<style lang="scss">
@import './styles/common';

@font-face {
    font-family: Omnes;
    src: url('/fonts/OmnesReg.ttf') format('truetype');
}

@font-face {
    font-family: Omnes Medium;
    src: url('/fonts/OmnesMed.ttf') format('truetype');
}
</style>
