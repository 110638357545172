import Auth from '@serv/Auth'
import Locale from '@serv/Locale'
import Login from '@comp/MrLogin.vue'
import Logout from '@comp/MrLogout.vue'
import Redirect from '@serv/Redirect'
import Support from '@comp/MrSupport.vue'
import { homePagePatientLoginMiddleware, logoutBeforeEnter } from '@/router/portalMiddleware'

// REGISTRATION
import PortalRegistrationFront from '@comp/portal-registration/PortalRegistrationFront.vue'
import PortalRegistrationHospitalNumber from '@comp/portal-registration/PortalRegistrationHospitalNumber.vue'
import PortalRegistrationLogin from '@comp/portal-registration/PortalRegistrationLogin.vue'

import { addOwnerAndRegionQueryParams } from '@/router/middleware'
import UnauthenticatedAuth from '@serv/Auth/UnauthenticatedAuth'
import Whoami from '@serv/Whoami'
import { createRouter as createVueRouter, createWebHistory } from 'vue-router'

const AccountSettingsPage = () => import('@comp/pages/AccountSettingsPage.vue')
const AdminContentPage = () => import('@comp/pages/AdminContentPage.vue')
const AdminUserPage = () => import('@comp/pages/AdminUserPage.vue')
const AdminUsersPage = () => import('@comp/pages/AdminUsersPage.vue')
const Auth0Login = () => import('@comp/Auth0Login.vue')
const ChangePassword = () => import('@comp/ChangePassword.vue')
const ClinicianListPage = () => import('@comp/pages/ClinicianListPage.vue')
const ClinicianPage = () => import('@comp/pages/ClinicianPage.vue')
const DepartmentListPage = () => import('@comp/pages/DepartmentListPage.vue')
const DepartmentPage = () => import('@comp/pages/DepartmentPage.vue')
const ExerciseTemplatesPage = () => import('@comp/pages/ExerciseTemplatesPage.vue')
const HelpPage = () => import('@comp/pages/HelpPage.vue')
const OverviewPage = () => import('@comp/pages/OverviewPage.vue')
const Patient = () => import('@comp/portal-registration/PortalWebRegistration.vue') // redirects according to Patient status
const PatientAddPathwayPage = () => import('@comp/pages/PatientAddPathwayPage.vue')
const PatientInvitePage = () => import('@comp/pages/PatientInvitePage.vue')
const PatientJourneyListPage = () => import('@comp/pages/PatientJourneyListPage.vue')
const PatientListPage = () => import('@comp/pages/PatientListPage.vue')
const PatientPage = () => import('@comp/pages/PatientPage.vue')

const PortalHome = () => import('@comp/pages/PortalHome.vue')
const PortalRegistrationRefer = () => import('@comp/portal-registration/nhs-self-referral/PortalRegistrationRefer.vue')
const PortalRegistrationReferAuth = () =>
    import('@comp/portal-registration/nhs-self-referral/PortalRegistrationReferAuth.vue')
const PortalRegistrationReferDetails = () =>
    import('@comp/portal-registration/nhs-self-referral/PortalRegistrationReferDetails.vue')
const PortalRegistrationReferFinish = () =>
    import('@comp/portal-registration/nhs-self-referral/PortalRegistrationReferFinish.vue')
const PortalRegistrationReferSuccess = () =>
    import('@comp/portal-registration/nhs-self-referral/PortalRegistrationReferSuccess.vue')
const PortalRegistrationReferSurvey = () =>
    import('@comp/portal-registration/nhs-self-referral/PortalRegistrationReferSurvey.vue')
const PortalRegistrationSelfNhs = () =>
    import('@comp/portal-registration/nhs-self-registration/PortalRegistrationSelfNhs.vue')
const PortalRegistrationSelfNhsAuth = () =>
    import('@comp/portal-registration/nhs-self-registration/PortalRegistrationSelfNhsAuth.vue')
const PortalRegistrationSelfNhsDetails = () =>
    import('@comp/portal-registration/nhs-self-registration/PortalRegistrationSelfNhsDetails.vue')
const PortalRegistrationSelfNhsPain = () =>
    import('@comp/portal-registration/nhs-self-registration/PortalRegistrationSelfNhsPain.vue')
const PortalRegistrationSelfNhsSuccess = () =>
    import('@comp/portal-registration/nhs-self-registration/PortalRegistrationSelfNhsSuccess.vue')
const PortalRegistrationSelf = () => import('@comp/portal-registration/PortalRegistrationSelf.vue')
const PortalRegistrationSelf2 = () => import('@comp/portal-registration/PortalRegistrationSelf2.vue')
const PortalRegistrationSuccess = () => import('@comp/portal-registration/PortalRegistrationSuccess.vue')
const PortalRegistration1Or2Fa = () => import('@comp/portal-registration/PortalRegistration1Or2Fa.vue')
const SurveyPage = () => import('@comp/pages/SurveyPage.vue')
const SurveysRoot = () => import('@comp/websurvey/SurveysRoot.vue')
const PortalRegistrationTerms = () => import('@comp/portal-registration/PortalRegistrationTerms.vue')
const PostLogin = () => import('@comp/PostLogin.vue')
const Register = () => import('@comp/MrRegister.vue')
const ResetPassword = () => import('@comp/ResetPassword.vue')
const ScorecardPage = () => import('@comp/pages/ScorecardPage.vue')
const Unsubscribe = () => import('@comp/MrUnsubscribe.vue')
const YourTeamPage = () => import('@comp/pages/YourTeamPage.vue')
/**
 * Application routes
 */

export default function createRouter() {
    const router = createVueRouter({
        history: createWebHistory(import.meta.env.BASE_URL),
        scrollBehavior() {
            return {
                top: 0,
                left: 0,
                behavior: 'smooth'
            }
        },
        routes: [
            {
                path: '/',
                name: 'Login',
                component: Login,
                beforeEnter: homePagePatientLoginMiddleware
            },
            {
                path: '/auth',
                name: 'Auth0',
                component: Auth0Login
            },
            {
                path: '/register',
                name: 'Register',
                component: Register
            },
            {
                path: '/patients/:id/:patientJourneyId?',
                name: 'PatientPage',
                component: PatientPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/new',
                name: 'PatientCreate',
                component: PatientInvitePage,
                meta: {
                    requiresAuth: true,
                    mode: 'create',
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/patient/:id/add-pathway',
                name: 'PatientAddPathway',
                component: PatientAddPathwayPage,
                meta: {
                    requiresAuth: true,
                    mode: 'create',
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/department-list',
                name: 'DepartmentList',
                component: DepartmentListPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['providerExec']
                }
            },
            {
                path: '/departments/:id',
                name: 'DepartmentPage',
                component: DepartmentPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['providerExec']
                }
            },
            {
                path: '/patient-list',
                name: 'PatientList',
                component: PatientListPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/patient-journey-list',
                name: 'PatientJourneyList',
                component: PatientJourneyListPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/clinician-list',
                name: 'ClinicianList',
                component: ClinicianListPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['producerExec', 'providerExec']
                }
            },
            {
                path: '/clinicians/:id',
                name: 'ClinicianProfile',
                component: ClinicianPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['producerExec', 'providerExec']
                }
            },
            {
                path: '/team',
                name: 'Team',
                component: YourTeamPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['hcp', 'adminAssistant', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/scorecard',
                name: 'Scorecard',
                component: ScorecardPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician', 'outcomesExec']
                }
            },
            {
                path: '/logout',
                name: 'Logout',
                component: Logout
            },
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: OverviewPage,
                meta: {
                    requiresAuth: true,
                    title: 'sidebarDashboard',
                    whitelistUsers: ['hcp', 'producerExec', 'providerExec', 'clinician']
                },
                props: {
                    report: 'Main'
                }
            },
            {
                path: '/exercise-templates',
                name: 'ExerciseTemplates',
                component: ExerciseTemplatesPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['clinician']
                }
            },
            {
                path: '/unsubscribe',
                name: 'Unsubscribe',
                component: Unsubscribe
            },
            {
                path: '/reset-password/:page?',
                name: 'ResetPassword',
                component: ResetPassword
            },
            {
                path: '/change-password/:page?',
                name: 'ChangePassword',
                component: ChangePassword
            },
            // Patient web surveys
            {
                path: '/patient-web-surveys/:patientJourneyId?/:activitySlug?',
                name: 'SurveysRoot',
                component: SurveysRoot
            },
            // Old-style dash surveys (MicroPort)
            {
                path: '/activities/:activitySlug/patients/:id',
                name: 'Survey',
                component: SurveyPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'clinician']
                }
            },
            // Patient registration
            {
                path: '/patient',
                name: 'Patient',
                component: Patient
            },
            // Legacy support
            {
                path: '/invite',
                name: 'PatientLegacy',
                component: Patient
            },
            {
                path: '/patient/front',
                name: 'PortalRegistrationFront',
                component: PortalRegistrationFront
            },
            {
                path: '/patient/frontnhs',
                name: 'PortalRegistrationFrontNhs',
                component: PortalRegistrationFront,
                props: {
                    isNhs: true
                },
                meta: {
                    layout: 'PortalLayout'
                }
            },
            {
                path: '/patient/login',
                name: 'PortalRegistrationLogin',
                component: PortalRegistrationLogin
            },
            {
                path: '/patient/hospitalnumber',
                name: 'PortalRegistrationHospitalNumber',
                component: PortalRegistrationHospitalNumber
            },
            {
                path: '/patient/invite',
                name: 'PortalRegistration1Or2Fa',
                component: PortalRegistration1Or2Fa
            },
            {
                path: '/patient/invite/terms',
                name: 'PortalRegistrationTerms',
                component: PortalRegistrationTerms
            },
            {
                path: '/patient/invite/success',
                name: 'PortalRegistrationSuccess',
                component: PortalRegistrationSuccess
            },
            {
                // Patient self-registration, 1st page
                path: '/invite/:surgeonPrefix',
                name: 'PortalRegistrationSelf',
                component: PortalRegistrationSelf
            },
            {
                // Patient self-registration, 2nd page (set provider, journey, op date)
                path: '/patient/invite/pathway',
                name: 'PortalRegistrationSelf2',
                component: PortalRegistrationSelf2
            },
            // These must go last as they are catch-all for /patient/invite/:clinicianSlug routes
            {
                path: '/patient/invite/:clinicianSlug',
                name: 'PortalRegistrationSelfNhs',
                component: PortalRegistrationSelfNhs,
                beforeEnter: logoutBeforeEnter
            },
            {
                path: '/patient/invite/:clinicianSlug/auth',
                name: 'PortalRegistrationSelfNhsAuth',
                component: PortalRegistrationSelfNhsAuth,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationSelfNhs', ...params)
            },
            {
                path: '/patient/invite/:clinicianSlug/details',
                name: 'PortalRegistrationSelfNhsDetails',
                component: PortalRegistrationSelfNhsDetails,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationSelfNhs', ...params)
            },
            {
                path: '/patient/invite/:clinicianSlug/pain',
                name: 'PortalRegistrationSelfNhsPain',
                component: PortalRegistrationSelfNhsPain,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationSelfNhs', ...params)
            },
            {
                path: '/patient/invite/:clinicianSlug/terms',
                name: 'PortalRegistrationSelfNhsTerms',
                component: PortalRegistrationTerms,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationSelfNhs', ...params)
            },
            {
                path: '/patient/invite/:clinicianSlug/success',
                name: 'PortalRegistrationSelfNhsSuccess',
                component: PortalRegistrationSelfNhsSuccess,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationSelfNhs', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug',
                name: 'PortalRegistrationRefer',
                component: PortalRegistrationRefer,
                beforeEnter: logoutBeforeEnter
            },
            {
                path: '/patient/refer/:clinicianSlug/auth',
                name: 'PortalRegistrationReferAuth',
                component: PortalRegistrationReferAuth,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/details',
                name: 'PortalRegistrationReferDetails',
                component: PortalRegistrationReferDetails,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/terms',
                name: 'PortalRegistrationReferTerms',
                component: PortalRegistrationTerms,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/survey',
                name: 'PortalRegistrationReferSurvey',
                component: PortalRegistrationReferSurvey,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/finish',
                name: 'PortalRegistrationReferFinish',
                component: PortalRegistrationReferFinish,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/success',
                name: 'PortalRegistrationReferSuccess',
                component: PortalRegistrationReferSuccess,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            {
                path: '/patient/refer/:clinicianSlug/survey/:patientJourneyId/:activitySlug',
                name: 'PortalRegistrationReferSurveySingle',
                component: SurveysRoot,
                beforeEnter: (...params) => addOwnerAndRegionQueryParams('PortalRegistrationRefer', ...params)
            },
            // User support pages
            {
                path: '/404',
                name: 'NotFound',
                component: Support,
                props: {
                    supportType: 'notFound'
                }
            },
            {
                path: '/dashboard', // user can hit refresh to reload dash
                name: 'ServiceUnavailable',
                component: Support,
                props: {
                    supportType: 'serviceUnavailable'
                }
            },
            {
                path: '/settings',
                name: 'Settings',
                component: AccountSettingsPage,
                meta: {
                    requiresAuth: true,
                    title: 'sidebarSettings',
                    whitelistUsers: []
                }
            },
            {
                path: '/support',
                name: 'Support',
                component: HelpPage,
                props: true,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['adminAssistant', 'hcp', 'producerExec', 'providerExec', 'clinician']
                }
            },
            {
                path: '/corin-redirect',
                name: 'PostLogin',
                component: PostLogin,
                props: true
            },
            // Internal admin pages
            {
                path: '/admin/users',
                name: 'AdminUsers',
                component: AdminUsersPage,
                props: true,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['admin']
                }
            },
            {
                path: '/admin/users/:id',
                name: 'AdminUser',
                component: AdminUserPage,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['admin']
                }
            },
            {
                path: '/admin/content',
                name: 'AdminContent',
                component: AdminContentPage,
                props: true,
                meta: {
                    requiresAuth: true,
                    whitelistUsers: ['admin']
                }
            },
            {
                path: '/portal/home',
                name: 'PortalHome',
                component: PortalHome,
                meta: {
                    webPortal: true,
                    requiresAuth: true
                }
            },
            {
                path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
                redirect: '/404'
            }
        ]
    })

    router.beforeEach((to, from, next) => {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (Auth.hasSession() || UnauthenticatedAuth.hasSession()) {
                return Whoami.getPermissions(router.options.routes, to).then(routeBlacklist => {
                    if (routeBlacklist.includes(to.name)) {
                        return next(false)
                    }

                    return next()
                })
            }
            // For auth0 we need to force page reload, that's why using JS instead of vue-router next()
            let route = router.resolve({
                name: 'Login',
                query: { redirect: to.fullPath }
            })

            window.location.assign(route.href)

            return next(false)
        }

        return next()
    })

    router.onError(error => {
        // check if error is due to async component loading, and if so reload the page
        // NOTE: if the message produced by vue-router ever changes, please update the below string check.
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            alert(Locale.getLanguageItem('errorAsyncComponentNotFound'))
            window.location.reload()
        }
    })

    Redirect.init(router)

    return router
}
