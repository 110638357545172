import Activity from '@model/Activity'
import ActivityListService from '@serv/ActivityListService'
import Logging from '@serv/Logging'
import moment from 'moment'
import store from '@/store'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'

/**
 * An instance of a scheduled activity (e.g. repeatingRelative instance) at an explicit time.
 * These are generated by evaluating Activity objects in the context of a specific PatientJourney.
 *
 * This mirrors the ScheduleEvent model from the mobile app, and also some of the functionality of the
 * ScheduleEventHandler, which keeps track of patient survey results that match the ScheduleEvent.
 */
class ScheduleEvent {
    constructor(object) {
        this.journeySlug = object.journeySlug
        this.activitySlug = object.activitySlug
        this.repeatIndex = object.repeatIndex
        this.startDate = object.startDate // string
        this.momentStart = moment(object.startDate) // string
        this.endDate = object.endDate // string
        this.patientJourney = object.patientJourney

        // When evaluated, this holds an array of (completed) SurveyResults from the PJ that match the content item and
        // date range. In ascending datetime order.
        // In this way, our ScheduleEvent object also includes functionality of the mobile app ScheduleEventHandler
        // object.
        this.surveyResults = []
        this.allJourneyResults = []
    }

    get activity() {
        const journey = store.state.resources.journeys[this.journeySlug]
        if (!journey) {
            Logging.warn(`Could not find journey for slug: ${this.journeySlug}`)

            return
        }

        store.commit('ensureActivities')
        const activity =
            journey.activitiesMap[this.activitySlug] || ActivityListService.getActivityBySlug(this.activitySlug)
        if (!activity) {
            Logging.warn(`Could not find activity for slug: ${this.activitySlug}`)

            return
        }

        return activity
    }

    get content() {
        const activity = this.activity
        if (!activity) {
            return
        }

        return store.state.content.content[activity.contentSlug]
    }

    containsTag(tag) {
        return this.activity.containsTag(tag)
    }
    // Get latest matching SurveyResult (across only this PJ) or undefined.
    get latestSurveyResult() {
        return this.surveyResults?.length > 0 ? this.surveyResults[this.surveyResults.length - 1] : undefined
    }

    // Get latest matching SurveyResult (across all PJs) or undefined.
    get latestAllJourneyResult() {
        return this.allJourneyResults?.length > 0
            ? this.allJourneyResults[this.allJourneyResults.length - 1]
            : undefined
    }

    /**
    this.surveyResults is matching only on the ScheduleEvent's PJ.
    In some cases we may want to check for results across ALL PJs, for example if we only want to show onboarding once across all
    PJs. we can do this by setting the "scope-all-journey-results" content tag.
    */
    get latestResult() {
        return this.containsTag('scope-all-journey-results') ? this.latestAllJourneyResult : this.latestSurveyResult
    }

    // Returns the status of the schedule event, based upon the MOST RECENT RESULT (if any).
    // So if there is a partial result and an older complete result, we'll still return partial.
    get status() {
        const result = this.latestResult
        if (!result) {
            return ScheduleEvent.Status.initial
        }

        return this.getStatusForResult(result)
    }

    // Returns the status of the schedule event, based upon any completed result (if any), or the most recent result (if any)
    // So if there is a partial result and an older complete result, we'll return complete.
    statusMostComplete() {
        const results = this.containsTag('scope-all-journey-results') ? this.allJourneyResults : this.results
        const firstCompleteResult = results.find(result => result.status == SurveyResult.Status.complete)
        if (firstCompleteResult) {
            return this.getStatusForResult(firstCompleteResult) // just maps result status to schedule event status
        }
        const lastResult = this.latestResult
        if (lastResult) {
            return this.getStatusForResult(lastResult) // just maps result status to schedule event status
        }

        return ScheduleEvent.Status.initial
    }

    // Return true only if we are in a 'complete state
    get isComplete() {
        return this.latestResult?.status == SurveyResult.Status.complete
    }

    get shouldHide() {
        return this.latestResult?.status == SurveyResult.Status.partial && this.latestResult?.enteredByClinician
    }

    // Get an array of all results statuses
    get resultStatuses() {
        return this.surveyResults.map(result => result.status)
    }

    // Returns the status of the ScheduleEvent
    getStatusForResult(result) {
        switch (result.status) {
            case SurveyResult.Status.partial:
                return ScheduleEvent.Status.partial
            case SurveyResult.Status.frozen:
                Logging.warn(`ScheduleEvent.getStatusForResult, unexpected frozen status for result ${result.uuid}`)

                return ScheduleEvent.Status.initial
            case SurveyResult.Status.complete:
                return this.getStatusWhenComplete(this.activity.redoType)
        }
    }

    // Return the ScheduleEventStatus when the associated Result is complete
    getStatusWhenComplete(activityRedoType) {
        switch (activityRedoType) {
            case Activity.RedoType.noneAndShow:
                return ScheduleEvent.Status.completeShow
            case Activity.RedoType.noneAndHide:
                return ScheduleEvent.Status.completeHide
            case Activity.RedoType.append:
                return ScheduleEvent.Status.completeAppend
            case Activity.RedoType.modify:
                return ScheduleEvent.Status.completeModify
            case Activity.RedoType.repeatUntilComplete:
                return ScheduleEvent.Status.completeHide
        }
    }

    // Return true only if the latestResult exists, is completed and can't be appended
    get isLatestResultCompleteFixed() {
        const result = this.latestResult
        if (!result) {
            return false
        }
        if (result.status != SurveyResult.Status.complete) {
            return false
        }
        const status = this.getStatusForResult(result)

        return status != ScheduleEvent.Status.completeAppend
    }

    /**
     * Calculate which of the specified SurveyResults match the ScheduleEvent - on content slug, and by fitting within
     * the date range. Sort the results increasing by time.
     * To handle bilateral case:
     * If the ScheduleEvent activity has a defined side, the SurveyResult must have the same side in order to match.
     */
    calculateMatchingSurveyResults(patientResults, patientJourneyResults, patientJourney) {
        this.surveyResults = patientJourneyResults
            .filter(surveyResult => {
                return surveyResult.matches({ event: this, patientJourney: patientJourney, matchJourney: true })
            })
            .sort((a, b) => a.time < b.time)

        this.allJourneyResults = patientResults
            .filter(surveyResult => {
                return surveyResult.matches({ event: this })
            })
            .sort((a, b) => a.time < b.time)
    }

    /**
     * Gets or creates the current active result.
     * Returns null if the status is completeShow or completeHide.
     * Creates a new result if 'initial' or 'completeAppend'.
     * Returns the latest result if 'partial' or 'completeModify'.
     *
     * NOTE: We don't currently support passing in "content" input, to create modified content, e.g. for
     * exercise routines.
     */
    getOrCreateCurrentResult() {
        let result, maxMultiple
        switch (this.status) {
            case ScheduleEvent.Status.partial:
                result = this.latestResult
                break

            case ScheduleEvent.Status.completeModify:
                result = this.duplicateLatestResultAndIncrementMultiple()
                break

            case ScheduleEvent.Status.completeAppend:
                maxMultiple = this.surveyResults.reduce(
                    (maxMultiple, result) => Math.max(maxMultiple, result.multiple || 0),
                    0
                )
                result = this.createResult(null, maxMultiple + 1)
                break

            case ScheduleEvent.Status.initial:
                result = this.createResult(null)
                break

            case ScheduleEvent.Status.completeHide:
            case ScheduleEvent.Status.completeShow:
                break
        }

        return result
    }
    /**
     * Copies the current result but increments the multiple.
     * Used when a user completes an event that can be modified.
     * The result is not actually modified but appended.
     * However, from the user's perspective, they are modified.
     */
    duplicateLatestResultAndIncrementMultiple() {
        const latestResult = this.latestResult
        if (!latestResult) {
            Logging.error(`duplicateLatestResultAndIncrementMultiple: Could not find latest result`)

            return
        }
        const duplicate = latestResult.getDuplicate()
        if (!duplicate) {
            Logging.error(`duplicateLatestResultAndIncrementMultiple: Could not duplicate latest result`)

            return
        }
        duplicate.multiple += 1

        // ResultsManager.shared.addResult(duplicate);
        return duplicate
    }

    // Creates a new SurveyResult.
    createResult(content = null, multiple = null) {
        const resultContent = content !== null ? content : this.content
        if (!resultContent) {
            Logging.error(`Activity with slug ${this.activitySlug} has no content`)

            return
        }
        const newResult = this.createActivityResult(resultContent, multiple)
        if (!newResult) {
            Logging.error('Could not create result')

            return
        }
        newResult.surveySlug = resultContent.slug

        return newResult
    }
    // Create a SurveyResult and add it to the results array.
    createActivityResult(content = null, multiple = null) {
        const result = SurveyResultsService.makeSurveyResult(content, this.activity.scheduleSlug, multiple)
        if (!result) {
            Logging.error(`Could not make Activity result for event with slug: ${this.activitySlug}`)
        }
        // Add to ScheduleEvent, PatientJourney, Patient
        // Pushing to end of list maintains sort order, as this SurveyResult should be newer than all others
        this.surveyResults.push(result)
        this.allJourneyResults.push(result)
        this.patientJourney.surveyResults.push(result)
        const patient = store.state.user.patients[this.patientJourney.patientId]
        patient?.surveyResults?.push(result)

        return result
    }
}

/**
The status of the ScheduleEvent. Its depends on the status of SurveyResults held on the object. The dash uses this
 status to know how to display the SurveyResult status in the patient portal UI, and what to do if clicked.
*/
ScheduleEvent.Status = {
    initial: 'initial', // no results have been generated for the event
    partial: 'partial', // the most recent result of the event has ResultsStatus.partial
    completeShow: 'completeShow', // the most recent result is .complete and the ActivityRedoType is .noneAndShow
    completeHide: 'completeHide', // the most recent result is .complete and the ActivityRedoType is .noneAndHide
    completeModify: 'completeModify', // the most recent result is .complete and the ActivityRedoType is .modify
    completeAppend: 'completeAppend' // the most recent result is .complete and the ActivityRedoType is .append
}

export default ScheduleEvent
