<template>
    <div
        class="position-relative survey-slider rounded-lg"
        :class="{
            'survey-slider--vertical': isVertical,
            'survey-slider--horizontal': !isVertical,
            'survey-slider--small': isSmall
         }"
        data-cy="survey-slider"
    >
        <div
            class="survey-slider__value text-center"
            data-cy="survey-slider-current-value"
        >
            {{ value }}
        </div>

        <div
            class="align-center mt-8 mt-lg-12"
            :class="{ 'd-flex flex-column pb-0': isVertical }"
        >
            <div
                v-if="isVertical"
                class="survey-slider__limit-description mb-6 mb-md-8 text-uppercase"
            >
                {{ getLangItem('contentHealthTopScoreText') }}
            </div>

            <div
                class="survey-slider__range w-100 position-relative"
                :class="{ 'flex-column-reverse d-flex align-center': isVertical }"
            >
                <div
                    class="survey-slider__limit survey-slider__limit--min px-2"
                    :class="{ 'text-start': !isVertical }"
                    data-cy="survey-slider-min-value"
                >
                    {{ currentStep.sliderLeftText || values.min }}
                </div>

                <MrSlider
                    v-if="values.min !== undefined && values.max !== undefined"
                    class="survey-slider__slider"
                    :step-size="stepSize"
                    :min="values.min"
                    :max="values.max"
                    :model-value="value"
                    :direction="direction"
                    @update:model-value="onChange"
                />

                <div
                    class="survey-slider__limit survey-slider__limit--max px-2"
                    :class="{ 'text-end': !isVertical }"
                    data-cy="survey-slider-max-value"
                >
                    {{ currentStep.sliderRightText || values.max }}
                </div>
            </div>

            <div
                v-if="isVertical"
                class="survey-slider__limit-description mt-6 mt-md-8 text-uppercase"
            >
                {{ getLangItem('contentHealthBottomScoreText') }}
            </div>
        </div>
    </div>
</template>

<script setup>
import MrSlider from '@comp/MrSlider.vue'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'

const props = defineProps({
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 0
    },
    isQuestionStepFreeTextValue: {
        type: Boolean,
        default: false
    },
    direction: {
        type: String,
        default: 'horizontal',
        validator: (direction) => ['horizontal', 'vertical'].includes(direction)
    },
    stepSize: {
        type: Number,
        default: 1
    },
    isSmall: {
        type: Boolean,
        default: false
    }
})

// initialize slider with min and max values
const value = ref(0)
const values = ref({ min: undefined, max: undefined })
const isVertical = computed(() => props.direction === 'vertical')

const store = useStore()
const taskHandler = computed(() => store.getters.taskHandler)
const currentStep = computed(() => taskHandler.value.currentStep)

onMounted(() => {
    values.value = {
        min: props.min || currentStep.value.sliderMinValue,
        max: props.max || currentStep.value.sliderMaxValue
    }

    init()
})

const init = () => {
    if (props.isQuestionStepFreeTextValue) {
        const number = parseFloat(taskHandler.value.currentStepResult.freeTextValue)
        if (isNaN(number)) {
            value.value = (values.value.min + values.value.max) / 2
        } else {
            value.value = number
        }
    } else {
        if (taskHandler.value.currentStepResult.value == undefined) {
            value.value = (values.value.min + values.value.max) / 2
        } else {
            value.value = taskHandler.value.currentStepResult.value
        }
    }

    value.value = +value.value
}

// actions onChange value
const onChange = (newValue) => {
    const precision = Math.ceil(Math.log10(1 / props.stepSize))
    value.value = parseFloat((Math.round(newValue / props.stepSize) * props.stepSize).toFixed(precision))

    if (props.isQuestionStepFreeTextValue) {
        taskHandler.value.currentStepResult.freeTextValue = newValue.toString()
    } else {
        taskHandler.value.currentStepResult.value = newValue
    }
    taskHandler.value.calcNextButtonStatus()
}
</script>
