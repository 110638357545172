import _ from 'lodash'
import BaseContent from '@model/content/BaseContent'
import ConfigManager from '@config/ConfigManager'
import InfoStep from '@model/content/InfoStep'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import NotifyService from '@serv/NotifyService'
import PatientJourneyWebSurveys from '@model/PatientJourneyWebSurveys'
import QuestionStep from '@model/content/QuestionStep'
import Request from '@serv/Request'
import Schedule from '@model/Schedule'
import StepResult from '@model/StepResult'
import store from '@/store'
import StringHelper from '@serv/StringHelper'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import TaskHandler from '@serv/TaskHandler'
import User from '@model/User'
import Utils from '@serv/Utils'
import { loadScript, unloadScript } from 'vue-plugin-load-script'

// components
import SurveyInfoStep from '@comp/survey/SurveyInfoStep.vue'
import SurveyPainScaleStep from '@comp/survey/SurveyPainScaleStep.vue'
import SurveyQuestionStep from '@comp/survey/SurveyStepQuestion.vue'
import SurveyScaleStep from '@comp/survey/SurveyScaleStep.vue'
import SurveyStepScaleVertical from '@comp/survey/SurveyStepScaleVertical.vue'

/**
 * Singleton for managing completion of surveys from within the dashboard.
 * Initially created to handle "dash surveys" for MicroPort, then extended for generic "web surveys".
 */
class SurveyService {
    constructor() {
        // Verbose logging, e.g. JS files
        this.isLoggingVerbose = false

        this.stepTypeToComponent = {
            [BaseContent.Type.healthScale]: {
                name: 'SurveyHealthScaleStep',
                component: SurveyStepScaleVertical
            },
            [BaseContent.Type.infoStep]: {
                name: 'SurveyInfoStep',
                component: SurveyInfoStep
            },
            [BaseContent.Type.painScale]: {
                name: 'SurveyPainScaleStep',
                component: SurveyPainScaleStep
            },
            [BaseContent.Type.sliderScale]: {
                name: 'SurveySliderScaleStep',
                component: SurveyScaleStep
            },
            [BaseContent.Type.question]: {
                name: 'SurveyQuestionStep',
                component: SurveyQuestionStep
            }
        }

        // Each successful vue-plugin-load-script successfully loaded is added to this list
        this.loadedJsFileItems = []
    }

    /**
     * From form data generated within a PatientSurveyPage, create a SurveyResult object.
     */
    createSurveyResultFromDashSurveyFormData(form1, form2, patient, activity) {
        const formData = _.merge({}, form1, form2)
        Logging.log(JSON.stringify(formData, null, 2))

        // Calculate score
        const scoreArray = activity.contentSlug.includes('kss')
            ? this.getKssScore(formData)
            : this.getHhsScore(formData)

        // Create SurveyResult
        const now = moment().format(Utils.serialisedDateTimeFormat)
        const surveyResult = new SurveyResult({
            patientId: patient.personaId,
            journeySlug: patient.journeySlug,
            scheduleSlug: activity.scheduleSlug,
            surveySlug: activity.contentSlug,
            endTime: now,
            scoreArray: scoreArray
        })

        // Set StepResults
        const stepResults = []
        Object.keys(formData).forEach(questionIndex => {
            const resultObject = formData[questionIndex]
            const stepResult = new StepResult({
                type: resultObject.type,
                stepSlug: resultObject.contentSlug,
                value: resultObject.value,
                endTime: now
            })
            stepResults.push(stepResult)
            surveyResult.stepSlugToStepResult[resultObject.contentSlug] = stepResult
        })
        surveyResult.stepResults = stepResults

        return surveyResult
    }

    /**
     * From form data and an array of question slug parts, return a map of slug part
     * to sum of answer values whose slugs match the part.
     */
    getSubscoresFromFormData(formData, parts) {
        const subscores = {}
        for (const part of parts) {
            const filteredObjects = Object.values(formData).filter(object => object.contentSlug.includes(part))
            const filteredValues = filteredObjects.map(object => parseInt(object.value))
            const sum = filteredValues.reduce((a, b) => a + b, 0)
            subscores[part] = sum
        }

        return subscores
    }

    /**
     * Get array of section scores for KSS.
     */
    getKssScore(formData) {
        const subscores = this.getSubscoresFromFormData(formData, ['knee', 'function'])

        return [
            {
                section: 'Knee',
                value: subscores.knee
            },
            {
                section: 'Function',
                value: subscores.function
            }
        ]
    }

    /**
     * Get array of section scores for HHS.
     */
    getHhsScore(formData) {
        const subscores = this.getSubscoresFromFormData(formData, ['rom', 'pain', 'function', 'aod'])

        return [
            {
                section: 'Rom',
                value: subscores.rom
            },
            {
                section: 'Pain',
                value: subscores.pain
            },
            {
                section: 'Function',
                value: subscores.function
            },
            {
                section: 'Aod',
                value: subscores.aod
            },
            {
                section: 'Score',
                value: subscores.rom + subscores.pain + subscores.function + subscores.aod
            }
        ]
    }

    /**
     * From a StepResult and Step, get the full JSON payload for the StepResult.
     * If the Step requires a value, but it is currently undefined, we still need to add a Result with no value.
     */
    getStepResultJson(stepResult, step) {
        const results = []
        if (stepResult.value == undefined) {
            if (!(step instanceof InfoStep)) {
                // Single text choice
                results.push({
                    type: step instanceof QuestionStep ? 'textChoice' : 'integer',
                    contentSlug: stepResult.stepSlug
                })
            }
        } else if (typeof stepResult.value == 'string') {
            // Single text choice
            results.push({
                type: 'textChoice',
                contentSlug: stepResult.stepSlug,
                value: stepResult.value,
                text: step.choiceValueToText[stepResult.value]
            })
        } else if (typeof stepResult.value == 'number') {
            // Integer
            results.push({
                type: 'integer',
                contentSlug: stepResult.stepSlug,
                value: stepResult.value
            })
        } else if (Array.isArray(stepResult.value)) {
            // Multiple text choices
            stepResult.value.forEach(value => {
                results.push({
                    type: 'textChoice',
                    contentSlug: stepResult.stepSlug,
                    value: value,
                    text: step.choiceValueToText[value]
                })
            })
        }
        if (stepResult.freeTextValue) {
            results.push({
                type: 'text',
                contentSlug: stepResult.stepSlug,
                value: stepResult.freeTextValue
            })
        }
        const object = {
            type: 'stepResult',
            contentSlug: stepResult.stepSlug,
            startTime: stepResult.startTime,
            endTime: stepResult.endTime,
            results: results
        }
        if (stepResult.id != undefined) {
            // PUT
            object.id = stepResult.id
        }

        return object
    }

    /**
     * From a SurveyResult, create an ActivityResult payload for POST or PUT
     */
    createSurveyResultPostPayload(survey, surveyResult, patient, patientJourney, activity) {
        /*
        Each StepResult needs expanding into the form:
            startTime: '',
            contentSlug: 'hhs-surv-pain-q1',
            endTime: '',
            type: 'stepResult',
            results: [
                {
                    contentSlug: 'hhs-surv-pain-q1',
                    value: '',
                    type: 'textChoice',
                    text: ''
                }
            ]
        */
        const stepResultsPayload = []
        for (let i = 0; i < survey.steps.length; i++) {
            // For development: accommodate mismatch in arrays, match by step slug
            const step = survey.steps[i]
            const stepResult = surveyResult.stepResults.find(stepResult => stepResult.stepSlug == step.slug)
            if (stepResult) {
                stepResultsPayload.push(this.getStepResultJson(stepResult, step))
            }
            // Otherwise, we should be able to use the simpler version below:
            // stepResultsPayload.push(
            //     this.getStepResultJson(surveyResult.stepResults[i], survey.steps[i])
            // )
        }

        /*
        Top level object needs the form:
        type: 'surveyResult',
        status: 'complete',
        startTime: '',
        endTime: '',
        multiple: 0,
        appVersion: '1.0.0',
        eventInfo: {
            journeySlug: 'mr-surv',
            contentSlug: 'hhs-surv',
            activitySlug: 'mr-surv-always-hhs-surv',
            repeatIndex: 0,
            milestone: {
                title: 'Operation',
                slug: 'operation',
                date: '2018-09-17'
            }
        }
        */
        const schedule = Schedule.get(activity.scheduleSlug)

        // Need to set repeatIndex as milestone.id, as required by the mobile app
        let repeatIndex, patientJourneyMilestoneId
        const milestone = Milestone.globalSlugs.includes(schedule.milestone)
            ? patient.getMilestoneOfSlug(schedule.milestone)
            : patientJourney.getMilestoneOfSlug(schedule.milestone)
        if (!milestone) {
            Logging.error(
                `Constructing web survey payload, could not get Milestone from slug: ${schedule.milestone} with activitySlug: ${activity.slug} and patientJourneyId: ${patientJourney.patientJourneyId}`,
                true
            )
            repeatIndex = 0
            patientJourneyMilestoneId = null
        } else {
            repeatIndex = milestone.id
            patientJourneyMilestoneId = milestone.id
        }

        const surveyResultPayload = {
            milestoneSlug: schedule.milestone,
            contentSlug: surveyResult.surveySlug,
            activitySlug: activity.slug,
            patientJourneyId: patientJourney.patientJourneyId,
            deviceOsVersion: Utils.getBrowser(),
            devicePlatform: navigator.platform,
            type: 'surveyResult',
            status: surveyResult.status,
            startTime: surveyResult.startTime,
            endTime: surveyResult.endTime,
            multiple: 0,
            repeatIndex: repeatIndex,
            patientJourneyMilestoneId: patientJourneyMilestoneId,
            appVersion: '1.0.0', // "special" version number indicates coming from dash not app
            isWebSurveyResult: true,
            syncTime: surveyResult.startTime,
            lastSyncTime: surveyResult.endTime,
            stepResults: stepResultsPayload,
            score: Object.values(surveyResult.scoreMap),
            uuid: surveyResult.uuid,
            version: survey.version,
            locale: survey.locale,
            originalCompletionDate: surveyResult.originalCompletionDate
        }

        return surveyResultPayload
    }

    // Initialise the list of ScheduleEvents that a patient can complete on the web.
    initWebSurveys(excludeEventsWithTags = []) {
        const patientJourneysSurveys = []
        const patient = store.state.user.user

        // Primary followed by sorted secondaries
        for (const patientJourney of patient.pathwayJourneys) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]
            if (!journey) {
                Logging.error(`Could not find journey: ${patientJourney.journeySlug}`)
                continue
            }

            // Filter down our existing PJ.scheduleEvents
            const scheduleEvents = this.filterScheduleEventsToWebSurveysScheduledNow(
                patient,
                patientJourney,
                excludeEventsWithTags
            )

            // Don't add PJ section at all, if no surveys to display
            if (scheduleEvents.length == 0) {
                continue
            }

            patientJourneysSurveys.push(
                new PatientJourneyWebSurveys({
                    patientJourneyId: patientJourney.patientJourneyId,
                    scheduleEvents: scheduleEvents
                })
            )
        }
        // Add to store
        store.commit('setPatientJourneysSurveys', patientJourneysSurveys)
        store.commit('setWebSurveysInitCompleted')
    }

    getPatientWebSurveyBySlug(scheduleEventContentSlug, patient) {
        patient = patient || store.state.user.user

        for (const patientJourney of patient.pathwayJourneys) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            let scheduleEvents = journey.activities
                .filter(scheduleEvent => scheduleEvent.contentSlug == scheduleEventContentSlug)
                .map(scheduleEvent => {
                    const webSurveyScheduleEvent = _.clone(scheduleEvent)
                    webSurveyScheduleEvent.patientJourneyId = patientJourney.patientJourneyId

                    return webSurveyScheduleEvent
                })

            const patientJourneysSurveys = []

            patientJourneysSurveys.push(
                new PatientJourneyWebSurveys({
                    patientJourneyId: patientJourney.patientJourneyId,
                    scheduleEvents: scheduleEvents
                })
            )

            store.commit('setPatientJourneysSurveys', patientJourneysSurveys)

            if (scheduleEvents?.length) {
                return [patientJourney.patientJourneyId, scheduleEvents[0]]
            }
        }
    }
    /**
     * Filter the PJ.scheduleEvents array to those corresponding to web survey activities that are scheduled now.
     */
    filterScheduleEventsToWebSurveysScheduledNow(patient, patientJourney, excludeEventsWithTags = []) {
        let filteredEvents = patientJourney.scheduleEvents.filter(event => {
            const content = event.content
            if (!content) {
                Logging.warn(`Removing ScheduleEvent for Activity ${event.activitySlug} as cannot find content`)
            }

            return content && content.type == BaseContent.Type.survey
        })

        // Filter to surveys scheduled now
        filteredEvents = filteredEvents.filter(event => {
            const content = event.content
            let isValid
            if (ConfigManager.devConfig.patientSurveysRandomAccess) {
                isValid = content && !content.isChecklistSurvey
            } else {
                const allowedTags = [
                    'isPromSurvey',
                    'isClinicalSurvey',
                    'isPremSurvey',
                    'isFeedbackSurvey',
                    'isPainSurvey',
                    'isTrackerSurvey'
                ].filter(tag => !excludeEventsWithTags.includes(tag))

                isValid = content && !!allowedTags.find(tag => content[tag])
            }
            const nowDate = Utils.serialisedDateNow
            isValid = isValid && nowDate >= event.startDate && nowDate < event.endDate

            // Remove if latestResult completed by a different user
            return isValid && (!event.latestResult || event.latestResult.createdById == patient.personaId)
        })

        return filteredEvents
    }

    // Get the number of incomplete web surveys.
    getNumIncompleteSurveys() {
        let count = 0
        for (const patientJourneyWebSurveys of store.state.portal.patientJourneysSurveys) {
            for (const event of patientJourneyWebSurveys.scheduleEvents) {
                if (!event.isComplete) {
                    count++
                }
            }
        }

        return count
    }

    /**
     * Get the first video that matches the schedule of a survey in our list.
     */
    getFirstSurveyVideo() {
        for (const patientJourneyWebSurveys of store.state.portal.patientJourneysSurveys) {
            // Each PatientJourney
            const patientJourney = store.state.user.patientJourneys[patientJourneyWebSurveys.patientJourneyId]
            /**
             * Each Activity. From BDD scenario:
             * To find the most relevant video, we iterate the list of surveys in the displayed order.
             * To determine if any survey has an associated video object:
             *  - Filter all videos on the survey's PJ by tag "survey-video"
             *  - Find any video where schedule start offset matches the survey schedule start offset
             *  - If any found, we're done
             */
            const journey = store.state.resources.journeys[patientJourney.journeySlug]
            if (!journey) {
                Logging.error(`Could not find journey: ${patientJourney.journeySlug}`)
                continue
            }
            const patientJourneySurveyVideoActivities = journey.activities.filter(activity => {
                const content = store.state.content.content[activity.contentSlug]

                return content && content.type == BaseContent.Type.video && content.tags.includes('survey-video')
            })

            // Each ScheduleEvent
            for (const event of patientJourneyWebSurveys.scheduleEvents) {
                const activity = event.activity
                if (activity) {
                    const surveySchedule = Schedule.get(activity.scheduleSlug)
                    const matchingVideos = patientJourneySurveyVideoActivities.filter(activity => {
                        const videoSchedule = Schedule.get(activity.scheduleSlug)

                        return (
                            videoSchedule.milestone == surveySchedule.milestone &&
                            videoSchedule.startOffset == surveySchedule.startOffset
                        )
                    })
                    if (matchingVideos.length > 0) {
                        const videoSlug = matchingVideos[0].contentSlug

                        return store.state.content.content[videoSlug]
                    }
                }
            }
        }
    }

    /**
     * Return an array of promises for survey JS files to LOAD (download).
     */
    getJsFileLoadPromises(survey) {
        if (this.isLoggingVerbose) {
            Logging.log(`Survey ${survey.slug}: ${survey.jsFiles.length} JS files to load`)
        }
        const promises = survey.jsFiles.map(item =>
            loadScript(item.blobUrlFull)
                .then(() => {
                    // File downloaded
                    this.loadedJsFileItems.push(item)
                    const text = `Downloaded JS file ${item.slug}`
                    this.showNetworkSuccess(text)
                    if (this.isLoggingVerbose) {
                        Logging.log(text)
                    }
                })
                .catch(error => {
                    // Failed to download JS file
                    const text = `Downloading JS file ${item.slug}: ${error}`
                    this.showNetworkError(text)
                    if (this.isLoggingVerbose) {
                        Logging.log(text)
                    }
                })
        )

        return promises
    }

    /**
     * Unload all JS files previously downloaded.
     * Usually called so that, when unloads complete, we clear the TaskHandler in the state.
     */
    unloadAllJsFiles(thenClearTaskHandler) {
        const promises = this.loadedJsFileItems.map(item =>
            unloadScript(item.blobUrlFull)
                .then(() => {
                    // File unloaded
                    const text = `Unloaded JS file ${item.slug}`
                    if (this.isLoggingVerbose) {
                        Logging.log(text)
                    }
                })
                // Don't log as errors - these can happen if we call unloadAllJsFiles() more than once, which we want to allow
                // However, we DO need the catch, otherwise iOS throws an Unhandled Promise Rejection error
                .catch(error => {
                    Logging.log(`Trying to unload nonexistent file: ${item.slug}: ${error}`)
                })
        )
        // Await all promises
        Promise.all(promises).then(() => {
            if (this.isLoggingVerbose) {
                Logging.log('Unloaded all JS files.')
            }
            this.loadedJsFileItems = []
            if (thenClearTaskHandler) {
                Logging.log('Clearing TaskHandler')
                store.commit('setTaskHandler', undefined)
            }
        })
    }

    /**
     * Initialise a TaskHandler for a specific patientJourney activity.
     * A successful initialisation results in a commit of the TaskHandler reference to the Vuex state, which in turn triggers the
     * appearance of the PatientSurvey component.
     *
     * Initialisation only occurs after completion of a set of promises, including:
     * - Download of any JS files required by the survey
     * - GET of any partial ActivityResult, if the Activity indicates that one exists
     *
     * TODO: Pass in SurveyResult, which is now created from ScheduleEvent
     */
    initialiseTaskHandlerForPatientJourneyActivity({
        patient,
        patientJourney,
        activity,
        currentUser,
        surveyResult,
        amendResult = true
    }) {
        const canViewCompleteActivities =
            currentUser?.persona == User.Persona.clinician &&
            store.state.user.owner.hasFeatureFlag('canEditPatientSurveyResults')

        // Is there any existing SurveyResult in the DB?
        let promises = []
        const survey = store.state.content.surveys[activity.contentSlug]

        if (!surveyResult || !amendResult) {
            const results = SurveyResultsService.getPatientJourneySurveyResultsMatchingActivity(
                patientJourney,
                activity,
                canViewCompleteActivities ? 'complete' : 'partial'
            )
            if (results.length > 0) {
                // Existing partial result
                Logging.log(`Survey ${survey.slug}: using existing partial ActivityResult`)
                surveyResult = results[0]
            } else {
                // No existing partial result - create one
                surveyResult = SurveyResultsService.makeSurveyResult(
                    survey,
                    activity.scheduleSlug,
                    false,
                    currentUser,
                    patient
                )
                surveyResult.activitySlug = activity.slug
                patientJourney.surveyResults.push(surveyResult)

                // Re-resolve the ScheduleEvents for this new SurveyResult - only if this is a clinician
                if (currentUser?.persona == User.Persona.clinician) {
                    const patientJourneyScheduleEvents =
                        SurveyResultsService.getPatientJourneyScheduleEventsMatchingActivity(patientJourney, activity)
                    patientJourneyScheduleEvents.forEach(event => {
                        event.calculateMatchingSurveyResults(
                            [surveyResult],
                            patientJourney.surveyResults,
                            patientJourney
                        )
                    })
                }
            }
        }

        // remove current scripts
        this.resetJsInterface()
        this.unloadAllJsFiles()

        // Add promises to download JS files
        const surveyJsLoadPromises = this.getJsFileLoadPromises(survey)
        if (surveyJsLoadPromises.length == 0) {
            this.surveyJsLoaded = true
        } else {
            promises = [...promises, ...surveyJsLoadPromises]
        }

        // Await all promises
        return Promise.all(promises).then(() => {
            const taskHandler = new TaskHandler(
                survey,
                surveyResult,
                patient,
                patientJourney,
                false // isLoggingVerbose
            )
            taskHandler.activity = activity // required for serialising result

            if (amendResult) {
                taskHandler.goToStep(taskHandler.survey.steps[0].slug)
            }

            store.commit('setTaskHandler', taskHandler) // triggers DetailView changing to PatientSurvey

            if (this.isLoggingVerbose) {
                this.logTaskJsInterface()
            }
        })
    }

    checkDuplicateNoRedoSurveyResults({ surveyResults, activity, patient, currentSurveyResult }) {
        if (activity.canRedo) {
            return false
        }

        const existingSurveyResult = surveyResults.find(
            surveyResult =>
                surveyResult.activitySlug === activity.slug &&
                surveyResult.status == SurveyResult.Status.complete &&
                surveyResult.resultPrmId !== currentSurveyResult.resultPrmId
        )

        if (existingSurveyResult) {
            Logging.error(
                `Duplicate survey result: redo type is ${activity.redoType}, however this survey ${existingSurveyResult.activitySlug} is already completed for patient ${patient.personaId}.`
            )

            return true
        }

        return false
    }

    // Callback function for TaskHandler to call when leaving a step, if StepResult.results have changed.
    // NOTE: This is also called for "Submit" at the end of survey.
    onStepResultChanged(taskHandler) {
        // Do nothing if mocking
        if (ConfigManager.isMockingServer) {
            return
        }
        if (!taskHandler.activity) {
            // e.g. for unit tests
            Logging.warn(
                'SurveyService.onStepResultChanged, no TaskHandler.activity, will not update result to server.'
            )

            return
        }
        // POST or PUT updated SurveyResult
        let shouldPost

        Logging.addSentryBreadcrumbs([
            `Survey step changed - survey ${taskHandler.survey.slug}`,
            `Survey length: ${taskHandler.patient.surveyResults.length}, current stepIndex: ${taskHandler.stepIndex}, lastStepIndex: ${taskHandler.lastStepIndex}`,
            `Survey skipped steps: ${Array.from(taskHandler.skippedStepSlugs).join(',')}`
        ])

        this.checkDuplicateNoRedoSurveyResults({
            surveyResults: taskHandler.patientJourney.surveyResults,
            activity: taskHandler.activity,
            patient: taskHandler.patient,
            currentSurveyResult: taskHandler.surveyResult
        })

        if (taskHandler.surveyResult.id) {
            shouldPost = false
        } else {
            // NOTE: The presence of uuid on the object will be used as an indicator that we have POSTed the result
            taskHandler.surveyResult.uuid = nanoid()
            shouldPost = true
        }
        const patient = store.getters.clinicianCompleteForPatient || store.state.user.user
        const payload = this.createSurveyResultPostPayload(
            taskHandler.survey,
            taskHandler.surveyResult,
            patient,
            taskHandler.patientJourney,
            taskHandler.activity,
            taskHandler.startTime,
            taskHandler.endTime
        )
        if (shouldPost) {
            const url = Request.Stem.patientWebSurveyResultCreate.replace('{patientId}', patient.patientId)
            Request.post(url, payload)
                .then(response => {
                    //this.showNetworkSuccess('Posting result')
                    // Store BE id on SurveyResult object, in case we want to PUT again
                    if (response.data) {
                        this.updateSurveyResultFromResponse(taskHandler.surveyResult, response.data)
                    } else {
                        Logging.error('POST to web survey results endpoint returned no ActivityResult ID')
                    }
                })
                .catch(error => this.showNetworkError(`Posting result: ${error}`))
        } else {
            const url = Request.Stem.patientWebSurveyResultUpdate
                .replace('{patientId}', patient.patientId)
                .replace('{resultId}', taskHandler.surveyResult.id)
            Request.put(url, payload)
                .then(() => {
                    //this.showNetworkSuccess('Updating result')
                })
                .catch(error => this.showNetworkError(`Updating result: ${error}`))
        }
    }

    /**
     * Update our SurveyResult (and StepResults) from the BE POST response
     */
    updateSurveyResultFromResponse(surveyResult, object) {
        surveyResult.id = object.id
        surveyResult.resultPrmId = object.id
        object.stepResults.forEach(responseStepResult => {
            const stepResult = surveyResult.stepResults.find(
                stepResult => stepResult.stepSlug == responseStepResult.contentSlug
            )
            if (stepResult) {
                stepResult.id = responseStepResult.id
            } else {
                // Failure logging
                if (ConfigManager.devConfig.patientSurveysNetworkErrorNotifications) {
                    NotifyService.error(`Error: Unresolved StepResult.contentSlug ${responseStepResult.contentSlug}`)
                }
                Logging.error(
                    `Could not find a local StepResult matching response StepResult.contentSlug ${responseStepResult.contentSlug}. All future PUTs of the SurveyResult will fail.`
                )
            }
        })
    }

    /**
     * Display network success message (if configured to do so.)
     */
    showNetworkSuccess(text) {
        if (ConfigManager.devConfig.patientSurveysNetworkSuccessNotifications) {
            NotifyService.success(`Success: ${text}`)
        }
    }

    /**
     * Display network error message (if configured to do so.)
     */
    showNetworkError(text) {
        if (ConfigManager.devConfig.patientSurveysNetworkErrorNotifications) {
            NotifyService.error(`Error: ${text}`)
        }
    }

    /**
     * Reset the web survey JS interface.
     * In the global namespace, sets to undefined each JS function that could be called from the 'native code'.
     */
    get taskJsFunctions() {
        return ['taskComplete', 'taskEnter', 'taskEnterStep', 'taskExit', 'taskExitStep']
    }
    resetJsInterface() {
        if (!window) {
            return
        }
        Logging.log('Resetting JS interface...')
        this.taskJsFunctions.forEach(fn => (window[fn] = undefined))
        // this.taskJsFunctions.forEach(fn => delete window[fn])
    }

    /**
     * For debugging: log all top-level window functions that start with our task... names
     */
    logTaskJsInterface() {
        if (!window) {
            return
        }
        Logging.log('Logging Task JS interface...')
        const fns = this.taskJsFunctions
        Object.keys(window).forEach(key => {
            for (const prefix of fns) {
                if (key.startsWith(prefix)) {
                    Logging.log(`window fn: ${key}, value: ${window[key]}`)
                    break
                }
            }
        })
        Logging.log('Done.')
    }

    /**
     * Clean up any survey results / step results requested on the patient page.
     * As of August 2023, this is required because the dash always pulls down the highest available version of any
     * content item, e.g. survey. However the survey result may be from an earlier content version, and in exceptional
     * cases we may then need to adjust the results to match the higher version of the item.
     *
     * patientSurveyResultsData is an array of JSON objects for the survey results.
     * patientSurveyStepResultsData is an array of JSON objects for the survey step results.
     *
     * NOTE: EXTREME CAUTION IS REQUIRED WHEN ADDING TO OR MODIFYING THIS CODE.
     * The code is modifying the survey results that have been returned from the DB to the dash, prior to displaying
     * those results to clinicians or patients. Every case should be thoroughly tested against the production data,
     * and should have verbose comments indicating what is taking place and why.
     */
    postProcessPatientSurveyResults(patientSurveyResultsData, patientSurveyStepResultsData) {
        patientSurveyResultsData.forEach(surveyResultObj => {
            if (surveyResultObj.isPostProcessed) {
                Logging.warn('Post-processing same JSON object twice - skipping...')
            } else {
                const surveyName = StringHelper.replaceAll(surveyResultObj.surveySlug, '-', '_')
                let fnName = `_postProcessSurveyResultForSurveySlug_${surveyName}`
                if (this[fnName]) {
                    this[fnName](surveyResultObj, patientSurveyStepResultsData)
                    surveyResultObj.isPostProcessed = true
                }
            }
        })
    }

    /**
     * Survey(s): hopco_presurg-redflag1-surv, hopco_presurg-redflag2-surv
     * Problem:
     * v1 had all 4 questions with "No"="2".
     * v2 changed these "No" values to "0".
     * All v1 results can be identified as they have no score sections.
     * We can assume that no future versions will have "No"="2".
     */
    _postProcessSurveyResultForSurveySlug_hopco_presurg_redflag1_surv(surveyResultObj, patientSurveyStepResultsData) {
        if (!surveyResultObj.scoreArray) {
            // v1 survey. Need to change step result 'No' values from '2' to '0'
            Logging.warn(
                `postProcessPatientSurveyResults, modifying step result values for ${surveyResultObj.surveySlug}`
            )
            const filteredStepResults = patientSurveyStepResultsData.filter(stepResultObj =>
                stepResultObj.contentSlug.includes('hopco_presurg-redflag1-surv-q')
            )
            filteredStepResults.forEach(stepResultObj => {
                if (stepResultObj.answerValue == '2') {
                    stepResultObj.answerValue = '0'
                }
            })
        }
    }
    _postProcessSurveyResultForSurveySlug_hopco_presurg_redflag2_surv(surveyResultObj, patientSurveyStepResultsData) {
        if (!surveyResultObj.scoreArray) {
            // v1 survey. Need to change step result 'No' values from '2' to '0'
            Logging.warn(
                `postProcessPatientSurveyResults, modifying step result values for ${surveyResultObj.surveySlug}`
            )
            const filteredStepResults = patientSurveyStepResultsData.filter(stepResultObj =>
                stepResultObj.contentSlug.includes('hopco_presurg-redflag2-surv-q')
            )
            filteredStepResults.forEach(stepResultObj => {
                if (stepResultObj.answerValue == '2') {
                    stepResultObj.answerValue = '0'
                }
            })
        }
    }

    /**
     * Survey(s): hopco_standard-spine-redflag-surv
     * Problem:
     * v1 had all 5 questions with "Yes"="0", "No"="1".
     * v2 had all 5 questions with "Yes"="1", "No"="0".
     * All v1 results can be identified as they have no score sections.
     * We can assume that no future versions will have "Yes"="1", "No"="0".
     */
    _postProcessSurveyResultForSurveySlug_hopco_standard_spine_redflag_surv(
        surveyResultObj,
        patientSurveyStepResultsData
    ) {
        if (!surveyResultObj.scoreArray) {
            // v1 survey. Need to change step result 'No' values to '0' and 'Yes' values to '1'.
            Logging.warn(
                `postProcessPatientSurveyResults, modifying step result values for ${surveyResultObj.surveySlug}`
            )
            const filteredStepResults = patientSurveyStepResultsData.filter(
                stepResultObj =>
                    stepResultObj.resultPrmId == surveyResultObj.resultPrmId &&
                    stepResultObj.contentSlug.includes('hopco_standard-spine-redflag-surv-q')
            )
            filteredStepResults.forEach(stepResultObj => {
                if (stepResultObj.answerValue == '1') {
                    stepResultObj.answerValue = '0'
                } else if (stepResultObj.answerValue == '0') {
                    stepResultObj.answerValue = '1'
                }
            })
        }
    }
}

export default new SurveyService()
